var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-users-register",attrs:{"title":_vm.title,"modal-class":"modal-primary","hide-footer":"","size":"lg","loading":""},on:{"hidden":_vm.close},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('validation-observer',{ref:"checkForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',[_c('b-row',[_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"user-document-type"}},[_vm._v("Tipo de Documento")])]),_c('validation-provider',{attrs:{"name":"document_type","vid":"document_type","rules":"chat-compose-required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",class:{ 'border-danger rounded': errors[0] },attrs:{"small":"","options":_vm.document_types,"reduce":function (val) { return val.id; },"label":"name","hint":"Seleccione un tipo de documento","disabled":_vm.disableAll || _vm.disable.document_type},model:{value:(_vm.client.document_type),callback:function ($$v) {_vm.$set(_vm.client, "document_type", $$v)},expression:"client.document_type"}})]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"user-document-number"}},[_vm._v("Número de Documento")])]),_c('validation-provider',{attrs:{"name":"doccument_number","vid":"doccument_number","rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###########'),expression:"'###########'"}],class:{ 'border-danger rounded': errors[0] },attrs:{"type":"text","placeholder":"Ingrese su número de documento","disabled":_vm.disableAll || _vm.disable.document_number},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchEntityByDocumentNumber($event)}},model:{value:(_vm.client.document_number),callback:function ($$v) {_vm.$set(_vm.client, "document_number", $$v)},expression:"client.document_number"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('b-button',{staticClass:"cursor-pointer p-0 m-0",attrs:{"size":"sm","variant":"outlined-primary","disabled":_vm.disable.search_button},on:{"click":_vm.searchEntityByDocumentNumber}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1)]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"user-first-name"}},[_vm._v(_vm._s(_vm.label.first_name))])]),_c('validation-provider',{attrs:{"name":"first name","vid":"first_name","rules":"chat-compose-required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{class:{ 'border-danger rounded': errors[0] },attrs:{"type":"text","placeholder":_vm.client.document_type == 1
                        ? 'Ingrese su nombre'
                        : 'Ingrese su razón social',"disabled":_vm.disableAll || _vm.disable.first_name},model:{value:(_vm.client.first_name),callback:function ($$v) {_vm.$set(_vm.client, "first_name", $$v)},expression:"client.first_name"}})],1)]}}],null,true)})],1)],1),(_vm.client.document_type == 1)?_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"user-last-name"}},[_vm._v("Apellidos")])]),_c('validation-provider',{attrs:{"name":"last name","vid":"last_name","rules":"chat-compose-required|min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{class:{ 'border-danger rounded': errors[0] },attrs:{"type":"text","placeholder":"Ingrese sus apellidos","disabled":_vm.disableAll || _vm.disable.last_name},model:{value:(_vm.client.last_name),callback:function ($$v) {_vm.$set(_vm.client, "last_name", $$v)},expression:"client.last_name"}})],1)]}}],null,true)})],1)],1):_vm._e(),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"user-phone"}},[_vm._v("Teléfono")])]),_c('validation-provider',{attrs:{"name":"phone","vid":"phone","rules":"max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('+## ###-###-###'),expression:"'+## ###-###-###'"}],class:{ 'border-danger rounded': errors[0] },attrs:{"type":"text","placeholder":"Ingrese su teléfono","disabled":_vm.disableAll},model:{value:(_vm.client.phone),callback:function ($$v) {_vm.$set(_vm.client, "phone", $$v)},expression:"client.phone"}})],1)]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"user-country"}},[_vm._v("País")])]),_c('validation-provider',{attrs:{"name":"country","vid":"country","rules":"chat-compose-required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",class:{ 'border-danger rounded': errors[0] },attrs:{"small":"","options":_vm.countries,"reduce":function (val) { return val.id; },"label":"name","hint":"Seleccione un país","disabled":_vm.disableAll},model:{value:(_vm.client.country),callback:function ($$v) {_vm.$set(_vm.client, "country", $$v)},expression:"client.country"}})]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"6"}},[_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between text-white"},[_c('label',{attrs:{"for":"user-email"}},[_vm._v("Correo")])]),_c('validation-provider',{attrs:{"name":"email","vid":"email","rules":"chat-compose-required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{class:{ 'border-danger rounded': errors[0] },attrs:{"autocomplete":"new-email","type":"email","placeholder":"ejemplo@gmail.com","disabled":_vm.disableAll},model:{value:(_vm.client.email),callback:function ($$v) {_vm.$set(_vm.client, "email", $$v)},expression:"client.email"}})],1)]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"light","disabled":_vm.disableAll},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),(_vm.clientModal.type != 'edit')?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"warning","disabled":_vm.disableAll},on:{"click":function($event){return _vm.initForm()}}},[_vm._v(" Limpiar ")]):_vm._e(),(!_vm.loading)?_c('b-button',{attrs:{"variant":"primary","disabled":invalid || _vm.disableAll},on:{"click":_vm.registerClient}},[_vm._v(" "+_vm._s(_vm.clientModal.type != "edit" ? "Guardar" : "Actualizar")+" ")]):_c('b-button',{attrs:{"variant":"primary","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Cargando... ")],1)],1)])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }